import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MedicalRecord from './MedicalRecord'
import Chromebook from './ChromeBook';
import { Row, Col, Card, CardImg, CardBody, CardTitle, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PanelHeader from '../PanelHeader/PanelHeader';
import "./Home.css";
import { Translate } from '../../../../helpers/Translate';
import axios from 'axios';
import Swal from 'sweetalert2';

//const img1 = require("./assets/img/schoology_logo.png").default;

import img12 from "./assets/img/menu.png"
import img2 from "./assets/img/ivy_transportation.png";

import wallet from "./assets/img/wallet.png";

const t = new Translate();




const Home = () => {

  const height = "100Px";
  const transjwt: any = useSelector(
    (state: any) => state.Parent.transjwt
  );
  const schoologyUserName: any = useSelector(
    (state: any) => state.Parent.schoologyUserName
  );




  const setLms = () => {

    axios.post(`${process.env.REACT_APP_LMS_BASURL}include/store_parent_token.php`,
      {

        username: schoologyUserName,
        emaildomain: "ivyis.org"
      }).then((res) => {


        const url = "https://lms.ivyis.org";
        window.open(url);

      }).catch((err) => {
        console.log(err)
        Swal.fire(
          t.translate("An Error Occurred"),
          "Something wrong happened!",
          "error"
        );
      })
  }

  return (
    <>

      <MedicalRecord />

      <Chromebook />
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col lg={3} md={4} sm={6} xs={6}>
          <a className="" style={{ cursor: "pointer" }} target='_blank' href={`${process.env.REACT_APP_LMS_BASURL}include/store_parent_token.php?username=${schoologyUserName}&emaildomain=ivyis.org`}>

              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={"https://staff.ivyis.org/images/ivylms.jpg"} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("IVY's LMS")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/cafeteria"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img12} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Cafeteria")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <a href={`https://transportation.ivyis.org/?hash=` + transjwt} target="_blank" className="">
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img2} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Transportation")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={3} md={4} sm={6} xs={6}>
            <NavLink to={"/dashboard/ewallet"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={wallet} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Smart wallet")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
        </Row>
        <br />
      </div>
    </>
  );
};

export default Home;