import React, { useEffect } from "react";
import {Routes, Route, Navigate } from 'react-router-dom';
import Login from "./components/Account/Loginc";
import DashBoard from "./components/DashBoard/DashBoardComponent";
import { actionCreators as ParentCreators } from "./store/Parent";
import Cookies from "js-cookie";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenerateCode from "./components/Parent/parentpages/GenerateCode";
import CheckCode from "./components/Parent/parentpages/CheckCode";
import NewPass from "./components/Parent/parentpages/NewPass";
import setAuthToken from "./helpers/setAuthToken";
import Swal from "sweetalert2";
import { parseJwt } from "./helpers/func";
import { setTokenExpiration } from "./utils/auth-refresh";
import { Translate } from "./helpers/Translate";
import ErrorBoundary from "./ErrorBoundry";
function App() {
  const dispatch: Dispatch<any> = useDispatch();
  const jwt: string | null = useSelector((state: any) => {return state.Parent.jwt});
  const dictionary_fetched:any = useSelector((state: any) => state.Parent.dictionary_fetched);
  const Parent:any = useSelector((state: any) => state.Parent);
  const token = Cookies.get("guardiantoken");

  //console.log(jwt);
  useEffect(() => {
    try {
      if (token && !jwt) {
       
        dispatch(ParentCreators.LoginIframe());
        dispatch(ParentCreators.FetchDictionary());
        setAuthToken(token);
        setTokenExpiration( parseJwt(token))
      } else {
        
        if(token){

          setTokenExpiration(parseJwt(token))
        }
        setAuthToken(jwt);
        dispatch(ParentCreators.FetchDictionary());
      }
    } catch (e) {
      let ee = e as any;
      Swal.fire(ee?.response?.data.msg || "Failed to login", "", "error");
    }
  }, []);

  let verified = null;
  if (token ) {
    setTokenExpiration(parseJwt(token))
    verified = (
      <Routes>
        <Route path="/dashboard" element={<DashBoard/>} />
        <Route path="/dashboard/*" element={<DashBoard/>} />
        <Route path="*" element={<Navigate to="/dashboard/home" />} />
      </Routes>
    );
  } else {
    verified = (
        <Routes>
          <Route path="/GenerateCode/*" element={<GenerateCode />}></Route>
          <Route path="/CheckCode/*" element={<CheckCode />}></Route>
          <Route path="/newpass/*" element={<NewPass />}></Route>
          <Route path="/login/*" element={<Login/>} />
          <Route path="*" element={<Navigate to="/login"/>} />
        </Routes>
    );
  }
  return (
    <ErrorBoundary>
      {
        !token?verified: dictionary_fetched === true?verified:<h1 className=" text-center"> Loading... </h1>
      }
      
      </ErrorBoundary>
  );
}

export default App;
