import React from "react";
import { Component, MouseEvent } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import { connect } from "react-redux";
//import { ApplicationState } from '../../store';

import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";

import DashBoardNav from "./DashBoardNavComponent";
import SideNav from "./SideNavComponent";

//import { ConnString } from '../../store/initialStatus';
import { Translate } from "../../helpers/Translate";

import DoPayment from "../Fees/pages/DoPayment";
import Payments from "../Fees/pages/Payments";
import Subscriptions from "../Fees/pages/Subscriptions";
import ViewProfile from "../Parent/parentpages/ViewProfile";
import EditProfile from "../Parent/parentpages/EditProfile";
import { actionCreators as ParentCreators } from "../../store/Parent";

import Resturant from "../Students/Resturant/Resturant";
import Cafeteria from "../Students/Resturant/Cafeteria";
import ChangePassword from "../Parent/parentpages/ChangePassword";
import ProfileMenu from "../Parent/parentpages/ProfileMenu";
import ParentMenu from "../Parent/parentpages/ParentMenu";
import ChatBody from "../Parent/parentpages/chatBody/ChatBody";
import ChatList from "../Parent/parentpages/chatList/ChatList";
import ChatContent from "../Parent/parentpages/chatContent/ChatContent";
import ViewStudents from "../Students/ViewStudents";
import EWallet from "../Students/ewallet";
import Home from "../Parent/parentpages/Home/Home";
import CafeteriaMenu from "../Students/Resturant/Menu";
import Transactions from "../Students/ewallet_Transactions";
import Intervention from "../Students/interventions/interventions";
import InterventionDetails from "../Students/interventions/interventionDetails";
import ProgressReport from "../Students/StudentProgress";
import MapGrowth from "../Students/MapGrowth/MapGrowth";
import ClinicVisits from "../Students/clinicvisits/clinicvisits";
import ClinicVisitDetails from "../Students/clinicvisits/clinicvisitdetails";
import ExcusesTables from "../Students/Excuses/ExcusesTable";
import ReinforcementsDetails from "../Students/ReinforcementDetails";
import Meetings from "../Students/Meetings/Meetings";
import Installments from "../Installments/Installments";
import EarlyDismissal from "../Students/earlydismissal/EarlyDismissal";
import ClassActivities from "../Students/dailyactivities/DailyActivities";
import WallOfLove from "../Students/WallOfLove/WallOfLove";
import AcademicInterventions from "../Students/AcademicInterventions/AcademicInterventions";
import LateArrival from "../Students/LateArrival/LateArrival";
import Schoolfees from "../Schoolfees/Schoolfees";
import Breadcrumbs from "./Breadcrumbs";

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetParent: (data: any) => dispatch(ParentCreators.GetParent(data)),
  SaveToken: (data: any) => dispatch(ParentCreators.SaveToken(data)),
});
function mapStateToProps(state: any) {
  return {
    guardian: state.Parent.guardian,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

class DashBoard extends Component<any, any> {
  state: any;
  t = new Translate();
  constructor(props: any) {
    super(props);
  }
  toggleSideBar(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    $("body").toggleClass("sb-sidenav-toggled");
  }
  userDropdown(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    $("#privateuserMenu").toggleClass("show");
  }
  EnglishTheme = React.lazy(() => import("../../Themes/ThemeEn"));
  ArabicTheme = React.lazy(() => import("../../Themes/ThemeEn"));

  //STEP 2:
  //create a parent component that will load the components conditionally using React.Suspense

  componentDidMount() {
    $("#preloader").hide();
  }

  render() {
    //
    interface pageInterface {
      PageId: number;
      Name: string;
      MenuItemId: number;
      PageTypeId?: number;
      Actions: Array<any>;
      Properties: Array<any>;
    }

    //
    return (
      <React.Fragment>
        <DashBoardNav
          Logout={this.props.Logout}
          toggleSideBar={this.toggleSideBar}
          userDropdown={this.userDropdown}
        />

        <div id="layoutSidenav">
          <SideNav />

          <div id="layoutSidenav_content">
            <main>
              <div className="container-fluid">
                <Breadcrumbs />
                {/* <h1 className="mt-4">{this.t.translate("Dashboard")}</h1>
                <ol className="breadcrumb mb-4">
                  <li className="breadcrumb-item active">
                    {this.t.translate("Dashboard")}
                  </li>
                </ol> */}

                <Routes>
                  <Route path="/dopayment" element={<DoPayment />} />
                  <Route path="/payments"  element={<Payments />} />
                  <Route path="/subscriptions" element={<Subscriptions />} />

                  <Route path="/chatbody" element={<ChatBody />} />
                  <Route path="/chatlist" element={<ChatList />} />
                  <Route path="/chatcontent" element={<ChatContent />} />

                  <Route
                    path="/viewprofile"
                    element={<ViewProfile />}
                  ></Route>
                  <Route
                    path="/editprofile"
                    element={<EditProfile />}
                  ></Route>

                  <Route
                    path="/changepassword"
                    element={<ChangePassword />}
                  ></Route>
                  <Route
                    path="/profilemenu"
                    element={<ProfileMenu />}
                  ></Route>
                  <Route
                    path="/parentmenu"
                    element={<ParentMenu />}
                  ></Route>

                  <Route
                    path={`/ewallet/transaction/:id`}
                    element={<Transactions />}
                  ></Route>
                  <Route
                    path="/ewallet"
                    element={<EWallet />}
                  ></Route>
                  <Route
                    path="/schoolfees"
                    element={<Schoolfees />}
                  ></Route>
                  <Route
                    path="/viewstudents"
                    element={<ViewStudents />}
                  ></Route>
                  <Route
                    path="/installments"
                    element={<Installments />}
                  ></Route>
                  <Route
                    path="/viewstudents/:id/progress-report"
                    element={<ProgressReport />}
                  ></Route>
                  <Route
                    path="/viewstudents/:id/map-growth"
                    element={<MapGrowth />}
                  ></Route>
                  <Route
                    path="/viewstudents/:id/Reinforcement-point"
                    element={<ReinforcementsDetails />}
                  ></Route>

                  <Route
                    path="/resturant"
                    element={<Resturant />}
                  ></Route>
                  <Route
                    path="/cafeteria"
                    element={<Cafeteria />}
                  ></Route>
                  <Route
                    path="/academicinterventions"
                    element={<AcademicInterventions />}
                  ></Route>
                  <Route
                    path="/interventions"
                    element={<Intervention />}
                  ></Route>
                  <Route
                    path="/interventions/:id"
                    element={<InterventionDetails />}
                  ></Route>

                  <Route
                    path="/excuses"
                    element={<ExcusesTables />}
                  ></Route>
                  <Route
                    path="/interventions/:id"
                    element={<InterventionDetails />}
                  ></Route>

                  <Route
                    path="/clinicvisits"
                    element={<ClinicVisits />}
                  ></Route>
                  <Route
                    path="/meetings"
                    element={<Meetings />}
                  ></Route>
                  <Route
                    path="/meetings/:id"
                    element={<Meetings />}
                  ></Route>

                  <Route
                    path="/clinicvisits/:id"
                    element={<ClinicVisitDetails />}
                  ></Route>

                  <Route
                    path="/cafeteriaMenu"
                    element={<CafeteriaMenu />}
                  ></Route>
                  <Route
                    path="/cafeteriaMenu"
                    element={<CafeteriaMenu />}
                  ></Route>

                  <Route
                    path="/latearrival"
                    element={<LateArrival />}
                  ></Route>
                  <Route
                    path="/earlydismissal"
                    element={<EarlyDismissal />}
                  ></Route>
                  <Route
                    path="/walloflove"
                    element={<WallOfLove />}
                  ></Route>
                  <Route
                    path="/classactivities/:id"
                    element={<ClassActivities />}
                  ></Route>

                  <Route path="*" element={<Home />}></Route>
                </Routes>
              </div>
            </main>

            <footer className="py-4 bg-light mt-auto">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between small">
                  <div className="text-muted">Copyright &copy; IVY 2020</div>
                  <div>
                    <a id="Privacy" href="#Privacy">
                      Privacy Policy
                    </a>
                    &middot;
                    <a id="Terms" href="#Terms">
                      Terms &amp; Conditions
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </React.Fragment>
    );
  }
}



export default connector(DashBoard);
